.contact-contact{
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e3e6f6;
  /* background-image: radial-gradient(#555, #000); */
  margin-top: -1px;
  padding: 100px 20px 50px;
  height: auto;
}
.home-contact-main-content{
  width: 90%;
  color: #54586d;
  text-align: center;
}
.google-map{
  width: 48%;
}
.home-class-h1{
  padding-bottom: 30px;
  font-size: 40px;
  font-style: italic;
  color: #535a82;
}
p{
  font-size: 14px;
}
.contact-p{
  padding-top: 20px;
}
.email{
  text-transform: lowercase;
}
.contact-button{
  width: 200px;
  margin-top: 30px;
  padding: 10px 40px;
  text-align: center;
  color: #e3e6f6;
  font-size: 15px;
  font-weight: 700;
  font-style: italic;
  border: none;
  outline: none;
  transition: 1s ease;
  border-radius: 10px;
  background: rgba(25, 154, 235, 0.3);
}
.contact-button:hover{
  background: royalblue;
}
.google-map{
  flex-basis: 48%;
}
iframe{
  width: 100%;
  height: 100%;
}
.location-header{
  display: block;
  margin-bottom: 5px;
  font-size: 19px;
  font-weight: 700;
}
.shop-contact{
  margin-top: 50px;
  padding: 100px 20px 50px;
  height: auto;
}

.marquee-home{
  margin-top: 100px;
}

@media screen and (max-width:700px){
  .home-class-h1{
    font-size: 25px;
  }
  .contact-p{
    padding-top: 20px;
  }
  .email{
    text-transform: lowercase;
  }
  h2{
    padding: 130px 0 20px;
    font-size: 16px;
  }
  .span-four{
    font-size: 18px;
  }
  .location-header{
    font-size: 16px;
  }
}