.body{
  width: 100%;
  min-height: 100vh;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #90c1df;
}
.form-container{
  width: 360px;
  padding: 15px;
  border-radius: 8px;
  background: #e3e6f6;
}
.form-container h1{
  font-size: 18px;
  text-align: center;
}
.ui-divider{
  height: 2px;
  width: 100%;
  margin: 10px auto;
  background: #c8cad3;
}
.ui-form{
  padding: 5px 10px;
}
.field{
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
label{
  margin: 1px 3px;
  font-size: 13px;
  font-weight: 600;
}
input{
  width: 100%;
  padding: 10px;
  outline: none;
  font-size: 13px;
  border-radius: 4px;
  border: 2px solid #dcdee5;
}
input[type='text']{
  text-transform: capitalize;
}
input::placeholder{
  font-size: 12.5px;
  font-style: italic;
}
button{
  width: 100%;
  text-align: center;
  padding: 7px;
  border: none;
  border-radius: 3px;
  font-weight: 800;
}
.submit-btn{
  background: rgb(7, 157, 7);
  color: #e3e6f6;
  transition: .6s ease-in-out;
}
.submit-btn:hover{
  background: rgb(9, 117, 9);
}
.creat-new{
  margin: 15px 0 10px;
  font-size: 12px;
}
.creat-new span{
  color: rgb(7, 51, 182);
  font-weight: 600;
}
.creat-account{
  background: royalblue;
  color: #e3e6f6;
  transition: .6s ease-in-out;
}
.creat-account a{
  text-decoration: none;
  color: #e3e6f6;
}
.creat-account a.active{
  color: orange;
}
.creat-account:hover{
  background: rgb(22, 46, 119);
}

.error-alert{
  color: rgb(200, 33, 14);
  font-size: 12px;
  padding: 3px 0 0 2px;
  font-weight: 600;
}
.submit-successed, .submit-failed{
  width: 100%;
  font-size: 12px;
  text-align: center;
  padding: 10px 0;
  margin: 5px 0;
  font-style: italic;
  font-weight: 900;
}
.submit-successed{
  color: rgb(6, 96, 6);
  background-color: rgba(0, 128, 0, 0.2);
}
.submit-failed{
  color: rgb(155, 15, 15);
  background-color: rgba(255, 0, 0, 0.2);
}