
.cart-container{
  width: 100%;
  min-height: 100vh;
  padding-top: 80px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #e3e6f6;
}
.cart-items{
  position: relative;
  width: 420px;
  margin: 30px auto;
  border-radius: 5px;
  border: 1px solid rgb(135, 141, 144);
  background: #fff;
}
.nn{
  width: 100%;
  border: 1px solid rgba(128, 131, 133, 0.2);
}
.cart-items-list{
  width: 100%;
  display: grid;
  padding: 2%;
  gap: 10px;
  align-items: center;
  grid-template-columns: 25% 50% 25%;
  border-bottom: 1px solid rgba(127, 129, 130, 0.2);
  /* display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0 20px 20px; */
}
.cart-items-header{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  font-weight: 600;
  height: 50px;
  color: #5e5b5b;
  border-bottom: 1px solid rgba(153, 159, 162, 0.2);
  background: white;
}
.cart-item-empty{
  padding: 10px 0;
  display: flex;
  color: rgb(239, 33, 10);
  align-items: center;
  justify-content: center;
  font-style: italic;
  font-size: 13px;
}
.cart-tem-detail{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cart-items-image{
  width: 70%;
  height: auto;
  margin-right: 10px;

}

.removeItem{
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  font-size: 17px;
  padding: 2px 3px;
  color: #4e1e1e;
  border: none;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  font-weight: 200;
  transition: 1s ease;
}
.removeItem:hover{
  background:rgb(205, 201, 197);
}
/* .removeItem button{
  border: none;
  width: 100%;
  color: #fff;
  text-align: center;
  background: transparent;
} */
.cart-product-quantity-controler{
  display: flex;

}
.cart-items-add, .cart-items-remove, .cart-items-quantity{
  padding: 0;
  width: 20px;
  padding: 2px 3px;
  align-items: center;
  justify-content: flex-start;
}
.cart-items-add{
  color: #fff;
  font-size: 16px;
  font-weight: 900;
  background: #989bac;
}
.cart-items-remove{
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(228, 36, 15, 0.5);
}
.minus{
  width: 60%;
  height: 1.5px;
  background: white;
}
.cart-items-quantity{
  background: #fff;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.total-price-container{
  display: flex;
  gap: 20px;
  padding: 7px;
  font-weight: 600;
  color: #5e5b5b;
  align-items: center;
  background: white;
  /* background: rgba(48, 61, 69, 0.2); */
}
.total-price-detail{
  width: 60%;
  display: flex;
  align-items: center;
  gap: 15px;
}
.cart-item-price{
  font-size: 13px;
  color: #2b2a2a;

}
.cart-item-name{
  font-size: 13px;
}
.buy-btn{
  width: 40%;
  padding: 12px 0;
  border: none;
  color: #000;
  font-size: 16px;
  font-style: italic;
  transition: 1s ease;
  background:#c3c4cf;
}
  
.buy-btn:hover{
  background: rgb(151, 172, 237);
}

@media screen and (max-width: 700px){
  .cart-container{
    padding-top: 60px;
  }
  .cart-items{
    width: 95%;
   
  }
}
