.product-container{
  padding-top: 100px;
  background: #e3e6f6;
}
.product-h2{
  color: #115a88;
  font-size: 25px;
  padding: 30px 0 20px 0;
  text-align: center;
}
.product-products{
  margin: 10px auto;
  width: 90%;
  column-gap: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; */
  /* background: rgba(255, 165, 0, 0.2); */
  /* background: rgba(17, 90, 136, 0.3); */
  /* background: rgb(253, 178, 40); */
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: -2px;
  
}
.product-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 20px 10px;
  border-radius: 5px;
  transition: 1s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.product-image{
  width: 100%;
}
.product-image img{
  width: 90% ;
  display: block;
  margin: auto;
  transition: 1s ease;
}
.product-image img:hover{
 transform: scale(1.1);
}
.product-name{
  font-size: 13px;
  font-weight: 600;
  padding: 10px 0;
}
.product-price{
  font-size: 13.5px;
  font-weight: 800;
  padding-bottom: 10px;
}
.add-to-cart-btn{
  margin-bottom: 10px;
  margin-top: 5px;
  width: 100%;
  overflow: hidden;
}

.product-button{
position: relative;
width: 100%;
padding: 7px 0;
font-size: 15px;
font-style: italic;
font-weight: 700;
color: white;
display: block;
cursor: pointer;
border: none;
outline: none;
transition: 1s ease;
border-radius: 5px;
background-color: #aeb0bd;
}
.product-button:hover{
  background-color: #7a7b87;
}
.add-cart-overlay{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: -101%;
  font-style: italic;
  transition: 1s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(26, 62, 170);
}
.add-cart-overlay.active{
  right: 0;
}

@media screen and (max-width:600px){
  .product-container{
    padding: 70px 20px 20px;
  }
  .product-h2{
    font-size: 25px;
    padding: 30px 0 10px;
  }
}

/* contact part */
.home-contact{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #e3e6f6;
  /* background-image: radial-gradient(#555, #000); */
  margin-top: -1px;
  padding: 100px 20px 50px;
  height: auto;
}
.home-contact-main-content{
  width: 90%;
  color: #54586d;
  text-align: center;
}
.google-map{
  width: 48%;
}
.home-class-h1{
  padding-bottom: 30px;
  font-size: 40px;
  font-style: italic;
  color: #535a82;
}
p{
  font-size: 14px;
}
.contact-p{
  padding-top: 20px;
}
.email{
  text-transform: lowercase;
}
.contact-button{
  width: 200px;
  margin-top: 30px;
  padding: 10px 40px;
  text-align: center;
  color: #e3e6f6;
  font-size: 15px;
  font-weight: 700;
  font-style: italic;
  border: none;
  outline: none;
  transition: 1s ease;
  border-radius: 10px;
  background: rgba(25, 154, 235, 0.3);
}
.contact-button:hover{
  background: royalblue;
}
.google-map{
  flex-basis: 48%;
}
iframe{
  width: 100%;
  height: 100%;
}
.location-header{
  display: block;
  margin-bottom: 5px;
  font-size: 19px;
  font-weight: 700;
}
.shop-contact{
  margin-top: 50px;
  padding: 100px 20px 50px;
  height: auto;
}

.marquee-home{
  margin-top: 100px;
}

@media screen and (max-width:700px){
  .home-class-h1{
    font-size: 25px;
  }
  .contact-p{
    padding-top: 20px;
  }
  .email{
    text-transform: lowercase;
  }
  h2{
    padding: 130px 0 20px;
    font-size: 16px;
  }
  .span-four{
    font-size: 18px;
  }
  .location-header{
    font-size: 16px;
  }
}