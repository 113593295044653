.header{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  padding: 20px;
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  color: #fff;
  background: #0e5b8b;
  /* justify-content: space-between; */
}
.logo{
  width: 70px;
}
.logo img{
  width: 100%;
}
.link-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.link-to-be-hidden{
  display: flex;
}
.company-name{
  font-size: 25px;
  font-weight: 900;
  margin-bottom: 10px;

}
.company-name span{
  color:orange;
}

ul{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
ul li{
  list-style: none;
  margin-right: 30px;
}

ul li a{
  text-decoration: none;
  color: #fff;
  font-size: 13px;
}
ul li a.active{
  color: orange;
}
.cart-icon{
  position: relative;
  font-size: 16px;
}
.counter{
  position: absolute;
  top: -7px;
  right: -9px;
  font-size: 13px;
}

/* ============== */
.menu-container{
  display: none;
}
.menu{
  display: flex;
  flex-direction: column;
  gap: 3px;
  border: none;
  background: transparent;
  cursor: pointer;
}
.menu-bar{
  width: 15px;
  height: 2px;
  background: white;
}
.narrow-divice-link-container{
  position: absolute;
  top: 100%;
  right: -101%;
  width: 150px;
  height: 100vh;
  background: #fff;
  padding-bottom: 50px;
  background: #06466e;
  transition: 1s ease;
}
.narrow-divice-link-container ul{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.narrow-divice-link-container ul li{
  margin-top: 20px;
}
.narrow-divice-link-container ul li a{
  color: white;
}
.narrow-divice-link-container ul li a.active{
  color: orange;
}
.remove-link{
  position: absolute;
  top: 7px;
  left: 7px;
  padding: 0 5px 2px;
  border-radius: 50%;
  font-size: 17px;
  border: none;
  color: #fff;
  width: unset;
  background: #021724;
}

/* ======================= */
@media screen and (max-width:700px){
  .header{
    padding: 15px;
  }
  .logo a{
    font-size: 18px;
  }
  .link-to-be-hidden{
    display: none;
  }
  .company-name{
    font-size: 20px; 
  }
  ul li{
    margin-right: 24px;
  }
  ul li a{
    font-size: 14px;
  }
  .menu-container{
    display: block;
  }
}