
.homemain-container{
  width: 100%;
  position: relative;
  padding: 130px 20px 120px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  align-items: center;
  justify-content: space-around;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.homemain-part-one{
  text-align: center;
}
.homemain-head{
  color: #abb2de;
  font-style: italic;
  font-size: 30px;
  font-weight: 900;
  padding: 20px 0 20px;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-image: linear-gradient( orange, red);
}
.homemain-quality{
  color: #abb2de;
  font-size: 30px;
  font-weight: 900;
}
.homemain-p{
  margin-top: 20px;
  color: #8e94bc;
  font-size: 15px;
  text-align: justify;
}
.homemain-button{
  width: 200px;
  display: block;
  margin: auto;
  margin-top: 15px;
  padding: 10px 0;
  color: #e3e6f6;
  transition: .8s ease;
  background: #0f5e8f;
}
a{
  text-decoration: none;
}
.homemain-button:hover{
  background: royalblue;
}
.homemain-part-two{
  color: #8e94bc;
}
.homemain-newsletter-head{
  font-size: 20px;
  padding: 10px 0;
  font-weight: 600;
}
.homemain-newsletter-p{
  font-size: 14px;
  margin-bottom: 5px;
}
.homemain-form{
  display: flex;
  width: 100%;
}
.homemain-input{
  border: none;
  flex-basis: 70%;
  border-radius: unset;
}
.homemain-btn{
  color: #e3e6f6;
  flex-basis: 28%;
  border: none;
  border-radius: unset;
  background: #0569a7;
  transition: .5s ease;
}
.homemain-btn:hover{
  background: royalblue;
}

@media screen and (max-width:700px){
  .homemain-container{
    padding: 100px 20px 50px;
  }
  .homemain-head{
    font-size: 24px;
  }
  .homemain-quality{
    font-size: 25px;
  }

}

/* contact Part */
.home-contact{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #e3e6f6;
  /* background-image: radial-gradient(#555, #000); */
  margin-top: -1px;
  padding: 100px 20px 50px;
  height: auto;
}
.home-contact-main-content{
  width: 90%;
  color: #54586d;
  text-align: center;
}
.google-map{
  width: 48%;
}
.home-class-h1{
  padding-bottom: 30px;
  font-size: 40px;
  font-style: italic;
  color: #535a82;
}
p{
  font-size: 14px;
}
.contact-p{
  padding-top: 20px;
}
.email{
  text-transform: lowercase;
}
.contact-button{
  width: 200px;
  margin-top: 30px;
  padding: 10px 40px;
  text-align: center;
  color: #e3e6f6;
  font-size: 15px;
  font-weight: 700;
  font-style: italic;
  border: none;
  outline: none;
  transition: 1s ease;
  border-radius: 10px;
  background: rgba(25, 154, 235, 0.3);
}
.contact-button:hover{
  background: royalblue;
}
.google-map{
  flex-basis: 48%;
}
iframe{
  width: 100%;
  height: 100%;
}
.location-header{
  display: block;
  margin-bottom: 5px;
  font-size: 19px;
  font-weight: 700;
}
.shop-contact{
  margin-top: 50px;
  padding: 100px 20px 50px;
  height: auto;
}

.marquee-home{
  margin-top: 100px;
}

@media screen and (max-width:700px){
  .home-class-h1{
    font-size: 25px;
  }
  .contact-p{
    padding-top: 20px;
  }
  .email{
    text-transform: lowercase;
  }
  h2{
    padding: 130px 0 20px;
    font-size: 16px;
  }
  .span-four{
    font-size: 18px;
  }
  .location-header{
    font-size: 16px;
  }
}