.sign-up{
  width: 100%;
  min-height: 100vh;
  padding: 88px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #90c1df;
}
.form-container{
  width: 360px;
  padding: 15px;
  border-radius: 8px;
  background: #e3e6f6;
}
.trial-alert{
  text-align: center;
  font-size: 13px;
  color: rgb(151, 101, 8);
  font-style: italic;
  padding: 5px 0 10px 0;
}
.form-container h1{
  font-size: 18px;
  text-align: center;
}
.ui-divider{
  height: 2px;
  width: 100%;
  margin: 10px auto;
  background: #c8cad3;
}
.ui-form{
  padding: 5px 10px;
}
.field{
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
label{
  margin: 1px 3px;
  font-size: 13px;
  font-weight: 600;
}
input{
  width: 100%;
  padding: 10px;
  outline: none;
  font-size: 14px;
  border-radius: 4px;
  border: 2px solid #dcdee5;
}
input[type='text']{
  text-transform: capitalize;
}
input::placeholder{
  font-size: 12.5px;
  font-style: italic;
}
button{
  width: 100%;
  text-align: center;
  padding: 7px;
  border: none;
  border-radius: 3px;
  font-weight: 800;
}
.submit-btn{
  background: rgb(7, 157, 7);
  color: #e3e6f6;
  transition: .6s ease-in-out;
}
.submit-btn:hover{
  background: rgb(9, 117, 9);
}


