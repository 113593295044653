
.about{
  width: 100%;
  position: relative;
  padding: 150px 20px 50px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.about-h1{
  color: #abb2de;
  font-style: italic;
  font-size: 40px;
}
.about-p{
  margin-top: 10px;
  color: #8e94bc;
  font-size: 14px;
  text-align: justify;
  
}
#about-content{
  width: 100%;
  padding: 50px 20px 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
#about-content .image-container{
  flex-basis: 40%;
}
#about-content .image-container img{
  width: 100%;
}
#about-content .about-main-content{
  flex-basis: 56%;
}
#about-h2{
  margin-top: 20px;
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 8px;
}
#about-content div p{
  margin-top: 0;
  font-size: 16px;
  line-height: 25px;
}
#about-content div marquee{
  background-color: #ccc;
  padding: 5px 0;
}
#about-marquee{
  margin-top: 20px;
  font-size: 14px;
}

@media screen and (max-width:700px){
  .about{
    padding: 100px 20px 50px;
  }
  #about-content{
    align-items: flex-end;
    justify-content: flex-end;
  }
  #about-content .image-container{
    flex-basis: 100%;
  }
  #about-content .image-container img{
    width: 100%;
  }
  #about-content .about-main-content{
    flex-basis: 100%;
    padding-left: 5%;
  }
  #about-h2{
    font-size: 27px;
  }
 
}